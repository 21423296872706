import React, { useRef } from 'react'
import ReactQuill from 'react-quill';
import { useTranslation } from 'react-i18next';
import "./style.css";
import 'react-quill/dist/quill.snow.css';

const editorSettings = {
    modules: {toolbar: [
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link'],
        ['clean']
      ],}
}

// Component to create a new post
const InputCreatePost = ({ postContent, postContentChange, publishPost, hidePost }) => {
    const { t } = useTranslation(["forum"]);
    const quillRef = useRef();

    const handleContentChange = (content) => {
        if (!quillRef.current) return;
        postContentChange(content);
    };

    return (
        <div>
            <ReactQuill
                theme="snow"
                ref={quillRef}
                modules={editorSettings.modules}
                value={postContent}
                onChange={handleContentChange}
            />
            <div className="message-content-buttons-mail">
                <button 
                    disabled={false} 
                    type="submit" 
                    className="btn-reply-submit" 
                    onClick={() => publishPost(quillRef.current.getEditor().getText().trim())}
                >
                    <span>{t("reply")}</span>
                </button>
                <button type="submit" className="btn-reply-cancel" onClick={hidePost}>
                    <span>{t("cancel")}</span>
                </button>
            </div>
        </div>
    );
};

export default InputCreatePost