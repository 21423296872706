import axios from "./interceptors";
import {
  PORT_API_GET_FORUM_BY_LEVEL_SUBJECT,
  PORT_API_GET_FORUM_DETAIL,
  PORT_API_FORUM_CREATE_POST,
  PORT_API_FORUM_UPDATE_POST,
  PORT_API_FORUM_DELETE_POST,
} from "../config/environment";

export const getForumsByLevelSubject = (page, pageSize, subjectCode = null, levelCode = null) => {

  let url = `${PORT_API_GET_FORUM_BY_LEVEL_SUBJECT}/api/forums/student?page=${page}&pageSize=${pageSize}`;
    if (subjectCode) {
        url += `&subjectCode=${subjectCode}`;
    }
    if (levelCode) {
        url += `&levelCode=${levelCode}`;
    }

  return axios.get(url);
};

export const getForumDetails = (forumId, page, pageSize, postId) => {
  if(typeof page === 'undefined' || page === null) {
    page = 1;
  }

  if(typeof pageSize === 'undefined' || pageSize === null) {
    pageSize = 10;
  }

  page = page - 1;

  let url = `${PORT_API_GET_FORUM_DETAIL}/api/forums/${forumId}?fromPage=${page}&limiter=${pageSize}`;
    if (postId) {
        url += `&forumParentId=${postId}`;
    }
  return axios.get(
      url
  );
};

export const createPost = (data) => {
  return axios({
    method: "POST",
    url: `${PORT_API_FORUM_CREATE_POST}/api/forums/${data.ForumId}/post/`,
    data: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
  });
};

export const updatePost = (data) => {
  return axios({
    method: "PUT",
    url: `${PORT_API_FORUM_CREATE_POST}/api/forums/${data.ForumId}/post/${data.Id}`,
    data: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
  });
};

export const deletePost = (forumId, postId) => {
  return axios({
    method: "DELETE",
    url: `${PORT_API_FORUM_DELETE_POST}/api/forums/${forumId}/post/${postId}`
  });
};
