import React, { useState, useEffect, useCallback } from "react";
import Menu from "../../menu";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import { Loader } from "../../../common/styled-components/loader";
import { getForumDetails, deletePost, createPost } from "../../../../api/forum";
import ConfirmDialog from "../../../common/confirm-dialog";
import Pagination from "@material-ui/lab/Pagination";
import { getTimeZone } from "../../../../utils/utils";
import moment from "moment";
import "moment-timezone";
import "./style.css";
import InputCreatePost from "./forum-input-create-post";
import ParentPosts from "./forum-posts";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MenuBookIcon from '@material-ui/icons/MenuBook';

const EmptyListStyled = styled.p`
  color: #ccc;
  display: block
  justify-content: center;
  text-align: center;
  margin: 3em auto;

  & img {
    display: block;
    margin: 1em auto 0.25em auto;
    font-size: 45px;
    width: 175px;
    opacity: .5;
  }

  & i {
    display: block;
    margin: 1em auto 0.25em auto;
    font-size: 45px;
    width: 175px;
    opacity: .5;
  }
`;

const ForumDetail = () => {
  const { forumId } = useParams();
  const history = useHistory();
  const { t } = useTranslation(["forum"]);
  const [currentPage, setCurrentPage] = useState(1);
  const [forum, setForum] = useState({});
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [itemSelected, setItemSelected] = useState({});
  const [showPost, setShowPost] = useState(false);
  const [postContent, setPostContent] = useState("");
  const [totalPosts, setTotalPosts] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize] = useState(10);
  const [refreshKey, setRefreshKey] = useState(0);
  const [visibleChildren, setVisibleChildren] = useState({});
  const [deletedPostId, setDeletedPostId] = useState(null);
  const [isPosting, setIsPosting] = useState(false);

  useEffect(() => {
    getForumDetailById();
  }, [forumId, currentPage, pageSize, refreshKey]);

  const getForumDetailById = useCallback(async () => {
    if (!forumId) return;

    try {
      const response = await getForumDetails(forumId, currentPage, pageSize);
      const { ForumModel, AuthorName, ForumPostModels, TotalPages, TotalItems } = response.data;
      
      setPosts(ForumPostModels)
      setTotalPages(TotalPages)
      setTotalPosts(TotalItems);
      
      setForum({ ...ForumModel, AuthorName });
      
    } catch (error) {
      console.error('Error fetching forum details:', error);
    } finally {
      setLoading(false);
    }
    
  }, [forumId, currentPage, pageSize, refreshKey]);

  const handleOpenConfirmDialog = (item) => {
    setItemSelected(item);
    setOpenConfirm(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirm(false);
  };

  const handleDeleteItem = async () => {
    setLoading(true);
    try {
      await deletePost(forumId, itemSelected.Id);
      setDeletedPostId(itemSelected.Id); // Set the deleted post Id to trigger a re-render
      handleCloseConfirmDialog();
    } catch (error) {
      console.error('Error deleting post:', error);
    }
    finally {
      setLoading(false);
    }
  };

  const handleChangePaginate = (event, value) => {
    setCurrentPage(value);
    setRefreshKey((oldKey) => oldKey + 1); // Trigger a re-render
  };

  const handleShowPost = () => {
    setShowPost(true);
  }
  
  const hidePost = () => {
    setShowPost(false);
  }

  // Handle new post content
  const handlePostContentChange = (content) => {
    setPostContent(content)
}

const addPost = async () => {
  try {
    // Double check if loading modal fails
    if (isPosting) return;
    setLoading(true);
    setIsPosting(true);
    const req = {
        ForumId: forumId,
        Body: postContent,
    }

    await createPost(req);

    setShowPost(false);
    setLoading(false);
    setPostContent('');
    setRefreshKey((oldKey) => oldKey + 1); // Trigger a re-render
    
  } catch (error) {
    console.error('Error in post creation or update:', error);
  } finally {
    setLoading(false);
    setIsPosting(false);
  }
}

const fetchResponses = async (postId, page) => {
  try {
    const res = await getForumDetails(forumId, page, pageSize, postId); 
    return res.data;
  } catch (error) {
    throw error;
  }
}
  return (
    <>
      <Menu />
      <div className="wrapper-content">
        <div className="center-container">
          <div>
            <button className="btn-back" onClick={() => history.goBack()}>
              <ArrowBackIcon fontSize="small"/> 
              <span>{t("goTo")}</span>
            </button>
          </div>
          <div className="wrapper-forum">
            <h1>{forum.Title}</h1>

            <div className="forum-description-detail">
            <p dangerouslySetInnerHTML={{ __html: forum.Description }} />
              <p className="forum-description-detail__txt-secondary">
                <strong>{t("author")}:</strong> {forum.AuthorName}
              </p>
              <p className="forum-description-detail__txt-secondary">
                <strong>{t("startDate")}:{" "}</strong>{forum.CreatedAt ? moment.utc(forum.CreatedAt, "MM/DD/YYYY h:mm a")
                  .tz(getTimeZone())
                  .format("MM/DD/YYYY hh:mm a") : ""}
              </p>
            </div>

            <div className="content-forum">
              <div className="foroHeader--TotalAnswer wrapper-add-post">
                <span> {t("answers")} ({totalPosts}) </span>
              </div>
              <div className="wrapper-foroHeader">
                <div className="foroHeader--name">
                  {JSON.parse(localStorage.getItem('GLOBAL_STATE'))?.user?.Name}
                </div>
                {showPost ? <InputCreatePost postContent={postContent} postContentChange={handlePostContentChange} publishPost={addPost} hidePost={hidePost}  /> 
                : 
                <button className="foroHeader--btnAddComment" onClick={handleShowPost}>
                  <i className="fas fa-plus"></i> {t("addReply")}
                </button>
                }
                
              </div>
              <ParentPosts
                key={refreshKey} 
                posts={posts} 
                handleArchiveClick={handleOpenConfirmDialog} 
                fetchResponses={fetchResponses}
                visibleChildren={visibleChildren}
                setVisibleChildren={setVisibleChildren}
                deletedPostId={deletedPostId}
                />
              {!posts.length && !loading && (
                <EmptyListStyled>
                  <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <MenuBookIcon />
                    <span>{t("emptyForum")}</span>
                  </div>
                  
                </EmptyListStyled>
              )}
              {totalPages > 1 && (
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handleChangePaginate}
                />
              )}
            </div>
          </div>
          {openConfirm && (
            <ConfirmDialog
              open={openConfirm}
              onClose={handleCloseConfirmDialog}
              handleDeleteItem={handleDeleteItem}
            />
          )}
          {loading && <Loader />}
        </div>
      </div>
    </>
  );
};

export default ForumDetail;
